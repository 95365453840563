'use client';

import { prepare_virtualPageUrl } from '@/lib/data-layer/utils';
import DataLayerPageComponent from '@/src/features/shared/data-layer-component';
import Error404 from '@/src/features/shared/error-404';
import { generateMetaDataForPageWithSeoInfo } from '@/src/utils/helpers/meta-data';
import { Metadata } from 'next';
import { useSearchParams } from 'next/navigation';
import React from 'react';
import { useEffect } from 'react';

const mockPageData = {
  page: {
    id: 'NULL',
    code: 'error',
    name: 'Error',
    category: null,
    midCategory: null,
    subCategory: null,
    type: null,
    sharingType: 'WebSite',
    heroButtonText: null,
    whatIsTitle: null,
    desktopImagePath1: null,
    mobileImagePath1: null,
    desktopImagePath2: null,
    mobileImagePath2: null,
  },
  seoInfo: {
    title: 'Bir hata ile karşılaştık',
    metaDescription: 'Bu sayfaya şu an ulaşamıyoruz.Diğer bilgilere anasayfa üzerinden ulaşabilirsiniz',
    headingTitle: 'Error',
    heroDescription: 'Error',
    friendlyUrl: null,
    entity: null,
    entityId: null,
    statusParameters: 0 as any,
    image: 'https://statics-hangikredi.mncdn.com/images/hklogo.png',
    badge: null,
  },
};

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const searchParams = useSearchParams();
  useEffect(() => {
    console.error(error);
    if (window.newrelic) {
      window.newrelic.noticeError(error);
    }
  }, [error]);

  const prepareVirtualPageUrl = prepare_virtualPageUrl({
    pathname: `/error`,
    searchParams: searchParams,
  });
  const prepareDataLayerData = {
    page: { ...mockPageData.page, virtualPageUrl: prepareVirtualPageUrl },
    otherData: mockPageData.seoInfo,
  };

  return (
    <>
      <DataLayerPageComponent type="page" argms={prepareDataLayerData} />
      <Error404 status="error" />
    </>
  );
}

export async function generateMetadata({ params, searchParams }: any): Promise<Metadata> {
  return generateMetaDataForPageWithSeoInfo(mockPageData.seoInfo ?? {});
}
